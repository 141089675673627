import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import validateStepOne from '../../utils/validation/step-one';
import { fetchCategories, updateFormStore } from '../../actions/formActions';
import { lookupGeoCoordinates, lookupPlaceByCoordinates } from '../../actions/geoActions';
import ViewWrapper from '../../components/ViewWrapper';
import Navigation from '../../components/Navigation';
import Map from '../../components/map/Map';
import GeoSelect from '../../components/geo-select/GeoSelect';
import InputWrapper from '../../components/InputWrapper';
import Heading from '../../components/common/Heading';
import Select from '../../components/common/Select';
import TextArea from '../../components/common/TextArea';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const OuterContainer = styled.form`
  height: 100%;
  max-width: 100%;
`;

const StyledFeedback = styled.div`
  margin: ${props => props.theme.errorMessageFeedback.margin};
  font-size: ${props => props.theme.errorMessageFeedback.fontSize};
  color: ${props => props.theme.errorMessageFeedback.color};
`;

const StyledLink = styled.a`
  background: ${props => props.theme.buttonSolid.backgroundColor};
  border: 2px solid ${props => props.theme.buttonSolid.borderColor};
  border-radius: ${props => props.theme.buttonSolid.borderRadius};
  color: ${props => props.theme.buttonSolid.color};
  cursor: pointer;
  display: inline-block;
  font-size: ${props => props.theme.buttonSolid.fontSize};
  font-weight: 400;
  overflow: hidden;
  padding: ${props => props.theme.buttonSolid.padding};
  position: relative;
  text-align: center;
  transition: all 0.3s ease;
  white-space: nowrap;
  vertical-align: middle;
  text-decoration: none;

  &:focus,
  &:hover {
    background: ${props => props.theme.buttonSolidHover.backgroundColor};
    color: ${props => props.theme.buttonSolidHover.borderColor};
  }
`;

class StepOne extends Component {
  /**
   * Initialize state.
   *
   * @type {Object}
   */
  constructor(props) {
    super(props);

    const { form } = this.props;

    this.state = {
      category: form.category,
      errors: form.errors,
      location: form.location,
      locationDescription: form.locationDescription,
      locationThroughMap: false,
      stepValidated: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateStep = this.validateStep.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    props.fetchCategories();
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.form.location) {
      this.setState({ location: nextProps.form.location });
    }
  }

  /**
   * Update the local form state on every change.
   * @param {Object} event
   */
  handleChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value }, this.validateStep);
  };

  /**
   * When a place is selected from the dropdown use the PDOK id to
   * fetch the geo coordinates for that position.
   */
  handleGeoChangeByDropdown = event => {
    if (event) {
      const { props } = this;
      const { id } = event;

      props.lookupGeoCoordinates(id);

      this.setState({
        locationThroughMap: true,
      });

      setTimeout(() => this.validateStep(), 500);
    }
  };

  /**
   * When the map is clicked use Leaflets lat/long and look up
   * the information associated with that position.
   */
  handleGeoChangeByMap = (lat, lng) => {
    const { props } = this;

    props.lookupPlaceByCoordinates(lat, lng);

    this.setState({
      locationThroughMap: true,
    });

    setTimeout(() => this.validateStep(), 500);
  };

  handleLocationChange = e => {
    const { value } = e.target;

    if (!value) {
      this.setState({
        locationThroughMap: false,
      });
    }

    this.handleChange(e);
  };

  /**
   * Validate the current step, and decide wether or not the user
   * may continue to the next step
   *
   * @return {Void}
   */
  validateStep = () => {
    const { category, location, locationDescription } = this.state;
    const { errors, isValid } = validateStepOne({ category, location, locationDescription });

    this.setState({ errors });

    if (isValid) {
      this.setState({ stepValidated: true });
    }
  };

  /**
   * Update the Redux state and update the local state with the same props
   * this ensures that both contain the same data.
   * @param {Object} event
   */
  handleSubmit = e => {
    e.preventDefault();
    const { props } = this;
    const { stepValidated } = this.state;

    this.validateStep();

    if (stepValidated) {
      props.updateFormStore(this.state);
    }
  };

  /**
   * Render the location view.
   *
   * @return {Object}
   */
  render() {
    const { categories, geo } = this.props;
    const { category, errors, location, locationDescription, stepValidated } = this.state;

    return (
      <OuterContainer key="form-step-one" onSubmit={this.handleSubmit}>
        <ViewWrapper>
          <InputWrapper>
            <Select
              error={errors.category}
              name="category"
              onChange={this.handleChange}
              options={categories}
              value={category}
            />
          </InputWrapper>
          {category === 'Verlichting' && (
            <InputWrapper>
              <p>
                Is een lichtmast defect of brandt een lamp niet? U maakt de melding via het volgende formulier:
              </p>
              <StyledLink href="https://storing.moononline.nl/gemeentelansingerland" target="_blank">
                Melding openbare verlichting
              </StyledLink>
              <p>
                De aannemer kan de storing dan zo snel mogelijk oplossen. Benieuwd naar de status? In het formulier kunt u op de kaart ook de status van de melding volgen.
              </p>
            </InputWrapper>
          )}
          {category !== 'Verlichting' && (
            <>
              <Heading center size={2} title="Waar is het?" />
              <InputWrapper>
                <p>Typ en selecteer een adres van de melding of klik op de locatie van de kaart.</p>
                <GeoSelect
                  error={errors.location}
                  forceAcceptEmpty
                  icon="location"
                  name="location"
                  onChange={this.handleLocationChange}
                  onOptionClick={this.handleGeoChangeByDropdown}
                  placeholder="Type de naam van een straat of klik op de kaart"
                  type="text"
                  value={location}
                />
              </InputWrapper>
            </>
          )}
        </ViewWrapper>
        {category !== 'Verlichting' && (
          <>
            <Map onClick={this.handleGeoChangeByMap} geoData={geo} />
            <ViewWrapper>
              <InputWrapper>
                <p>Met een omschrijving kunt u de locatie van de melding nog verder verduidelijken. Een omschrijving van de melding vult u in wanneer u op volgende klikt.</p>
                <TextArea
                  error={errors.locationDescription}
                  forceAcceptEmpty
                  name="locationDescription"
                  onChange={this.handleChange}
                  placeholder="Omschrijving van de locatie"
                  rows="4"
                  value={locationDescription}
                />
              </InputWrapper>
              <StyledFeedback>* verplicht veld</StyledFeedback>
              <Navigation stepValidated={stepValidated} />
            </ViewWrapper>
          </>
        )}
      </OuterContainer>
    );
  }
}

StepOne.propTypes = {
  categories: PropTypes.instanceOf(Array).isRequired,
  form: PropTypes.shape({
    category: PropTypes.string,
    locationDescription: PropTypes.string,
    location: PropTypes.string,
  }).isRequired,
  geo: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  categories: state.categories,
  form: state.form,
  geo: state.geo,
});

const mapDispatchToProps = {
  fetchCategories,
  lookupGeoCoordinates,
  lookupPlaceByCoordinates,
  updateFormStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepOne);
