import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Wizard, Steps, Step } from 'react-albus';
import styled from 'styled-components';

import IntroText from '../../components/IntroText';
import ProgressBar from '../../components/ProgressBar';
import StepOne from '../StepOne';
import StepTwo from '../StepTwo';
import StepThree from '../StepThree';
import StepFour from '../StepFour';

/**
 * Styling of the component.
 *
 * @type {Object}
 */
const StyledStepContainer = styled.div`
  position: absolute;
  width: 100%;
`;

const childFactoryCreator = classNames => child =>
  React.cloneElement(child, {
    classNames,
  });

class WizardSteps extends Component {
  /**
   * Initialize state.
   *
   * @type {Object}
   */
  state = {};

  /**
   * Types of props.
   *
   * @type {Object}
   */
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
  };

  /**
   * Mapping of step IDs to document titles.
   */
  stepTitles = {
    locatie: 'Stap 1: Waar is het?',
    situatie: 'Stap 2: Waar gaat het om?',
    gegevens: 'Stap 3: Mijn gegevens',
    controleren: 'Stap 4: Controle',
  };

  /**
   * Updates the document title based on the current step.
   *
   * @param {string} stepId - The ID of the current step.
   */
  updateDocumentTitle = stepId => {
    const siteTitle = 'Buiten Op Orde - Gemeente Lansingerland';
    const title = this.stepTitles[stepId] ? `${this.stepTitles[stepId]} - ${siteTitle}` : siteTitle;
    document.title = title;
  };

  /**
   * Render the application.
   *
   * @return {Object}
   */
  render() {
    const { history } = this.props;

    return (
      <Wizard
        history={history}
        render={({ step, steps }) => {
          this.updateDocumentTitle(step.id);

          return (
            // The inline style below is for IE11 so it centers the content properly
            // can be removed once IE11 is now longer required.
            <div style={{ flexDirection: 'column', display: 'flex' }}>
              <IntroText
                text="Is er iets kapot, vies of niet in orde in de openbare ruimte? Met vier eenvoudige stappen
            geeft u dit aan ons door."
              />

              <ProgressBar steps={steps} step={step} />
              <TransitionGroup
                childFactory={childFactoryCreator(history.action === 'POP' ? 'back' : 'forward')}
              >
                <CSSTransition
                  key={step.id}
                  classNames={history.action === 'POP' ? 'back' : 'forward'}
                  timeout={500}
                >
                  <StyledStepContainer>
                    <Steps key={step.id} step={step}>
                      <Step id="locatie">
                        <StepOne />
                      </Step>
                      <Step id="situatie">
                        <StepTwo />
                      </Step>
                      <Step id="gegevens">
                        <StepThree />
                      </Step>
                      <Step id="controleren">
                        <StepFour />
                      </Step>
                    </Steps>
                  </StyledStepContainer>
                </CSSTransition>
              </TransitionGroup>
            </div>
          );
        }}
      />
    );
  }
}

export default withRouter(WizardSteps);
